import EmailFieldWithFormStyle from '@client/components/generic/EmailFieldWithFormStyle';
import FormError from '@client/components/generic/FormError';
import TextInputWithFormStyle from '@client/components/generic/TextInputWithFormStyle';
import { getCurrentUser } from '@client/store/selectors/auth.selectors';
import { formatPhoneInputForDisplay } from '@client/utils/string.utils';
import { isValidPhoneNumber } from '@client/utils/validations.forms';
import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export type UserApiInfo = {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
};

type Props = {
  eventNamePrefix: string;
  parentEventName: string;
  theme: Theme;
  onChange: (user: UserApiInfo) => void;
};

export const IncompleteUserInfoForm = ({
  eventNamePrefix,
  parentEventName,
  theme,
  onChange,
}: Props) => {
  const currentUser = useSelector(getCurrentUser);
  const [firstNameInputValue, setFirstNameInputValue] = useState(
    currentUser?.first_name || ''
  );
  const [lastNameInputValue, setLastNameInputValue] = useState(
    currentUser?.last_name || ''
  );
  const [emailInputValue, setEmailInputValue] = useState(
    currentUser?.email || ''
  );
  const [phoneInputValue, setPhoneInputValue] = useState(
    currentUser?.phone || ''
  );

  useEffect(() => {
    onChange({
      first_name: firstNameInputValue,
      last_name: lastNameInputValue,
      email: emailInputValue,
      phone: phoneInputValue,
    });
    /* Adding onChange causes infinite loop, we need to leave it out */
  }, [
    firstNameInputValue,
    lastNameInputValue,
    emailInputValue,
    phoneInputValue,
  ]);

  return (
    <div className={theme.IncompleteUserInfoForm}>
      <TextInputWithFormStyle
        required
        type="text"
        label="First Name*"
        aria-label="First Name"
        ariaDescribedby="error-first-name"
        name="first-name"
        value={firstNameInputValue}
        role="textbox"
        onChange={(e) => {
          setFirstNameInputValue(e.target.value);
        }}
        data-testid="first-name-input"
        error={
          <FormError
            theme={theme}
            value={firstNameInputValue ? '' : 'First name is required'}
            ariaDescribedby="error-first-name"
          />
        }
        data-event-name={`${eventNamePrefix}_first_name`}
        data-parent-event-name={parentEventName}
      />
      <TextInputWithFormStyle
        required
        type="text"
        label="Last Name*"
        aria-label="Last Name"
        ariaDescribedby="error-last-name"
        name="last-name"
        value={lastNameInputValue}
        role="textbox"
        data-event-name={`${eventNamePrefix}_last_name`}
        data-parent-event-name={parentEventName}
        onChange={(e) => {
          setLastNameInputValue(e.target.value);
        }}
        data-testid="last-name-input"
        error={
          <FormError
            theme={theme}
            value={lastNameInputValue ? '' : 'Last name is required'}
            ariaDescribedby="error-last-name"
          />
        }
      />
      <EmailFieldWithFormStyle
        required
        label="Email*"
        ariaDescribedby="error-email"
        theme={theme}
        data-event-name={`${eventNamePrefix}_email`}
        data-parent-event-name={parentEventName}
        onChange={(e) => {
          setEmailInputValue(e.target.value);
        }}
        data-testid="email-input"
        value={emailInputValue}
      />
      <TextInputWithFormStyle
        required
        type="text"
        label="Phone*"
        ariaDescribedby="error-phone"
        data-event-name={`${eventNamePrefix}_phone`}
        data-parent-event-name={parentEventName}
        onChange={(e) => {
          setPhoneInputValue(formatPhoneInputForDisplay(e.target.value));
        }}
        value={formatPhoneInputForDisplay(phoneInputValue)}
        data-testid="phone-input"
        error={
          <FormError
            theme={theme}
            value={
              phoneInputValue && isValidPhoneNumber(phoneInputValue)
                ? undefined
                : !phoneInputValue
                  ? 'Phone is required'
                  : 'Invalid phone number'
            }
            ariaDescribedby="error-phone"
          />
        }
      />
    </div>
  );
};

const ThemedIncompleteUserInfoForm = themr(
  'IncompleteUserInfoForm',
  undefined
)(IncompleteUserInfoForm);

export default ThemedIncompleteUserInfoForm;
