import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import theme from '@client/css-modules/YourTeam/YourTeamCTAs.css';
import PillButton from '@client/components/generic/PillButton';
import YourTeamMainCTAButton from '@client/components/YourTeam/YourTeamMainCTAButton';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import {
  getIsLoggedIn,
  getUserHasFirstnameLastnameAndEmail,
} from '@client/store/selectors/auth.selectors';
import { useReportYourTeamEvent } from '@client/components/YourTeam/utils/your-team.utils';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import { authModalShow } from '@client/store/actions/auth.actions';
import {
  selectYourTeamModalType,
  setYourTeamModalType,
} from '@client/store/slices/your-team.slice';

type CTAText = {
  text: string;
};

type YourTeamCTAsProps = {
  ctaTextArr: CTAText[];
  dataHcName: string;
  useLOTabAnalyticsEventNames?: boolean;
  onSubmit?: (message: string) => void;
};

const YourTeamCTAs: React.FC<YourTeamCTAsProps> = ({
  ctaTextArr,
  dataHcName,
  useLOTabAnalyticsEventNames,
  onSubmit,
}) => {
  const dispatch = useDispatch();
  const reportYourTeamEvent = useReportYourTeamEvent();
  const userHasFirstnameLastnameAndEmail = useSelector(
    getUserHasFirstnameLastnameAndEmail
  );
  const allowUnauthenticatedAccess = useSelector(
    getIsFeatureEnabled('your_team_unauthenticated')
  );
  const isLoggedIn = useSelector(getIsLoggedIn);
  const currentYourTeamModalType = useSelector(selectYourTeamModalType);
  const [selectedCta, setSelectedCta] = useState<null | string>(null);
  const { activePrimaryColor } = useCobrandStyles();
  const customBorderRadius = '35px';
  const customBackgroundColor = '#E9E9E9';
  const customTextColor = '#4a4a4a';

  const selectedCtaButtonStyling = {
    customBorderRadius,
    customBackgroundColor: '#4A4A4A',
    customTextColor: '#FFFFFF',
  };

  const LenderCTAButtonPDPConfigStyling = {
    customBorderRadius,
    customBackgroundColor,
    customTextColor,
  };

  const defaultSubmitButtonStyling = {
    customBackgroundColor: '#C8C8C8',
    customTextColor: '#FFFFFF',
  };

  const selectedSubmitButtonStyling = {
    customBackgroundColor: activePrimaryColor,
    customTextColor: '#FFFFFF',
  };
  const sendBtnText = 'Send';

  const handleClickCTA = (index, ctaText) => {
    /* If not logged in, prompt for login and exit early. User can then click button again after authenticating */
    if (!isLoggedIn) {
      dispatch(
        authModalShow({
          /* To open Your Team to the current tab after successful SSO */
          afterAuthAction: setYourTeamModalType({
            modalType: currentYourTeamModalType,
          }),
          subHeadingText: {
            login: 'Please log in to your account',
            'sign-up': 'Please sign up',
          },
          headingText: {
            login: 'Submit your message',
            'sign-up': 'Submit your message',
          },
        })
      );
      return;
    }

    switch (index) {
      case 0:
        reportYourTeamEvent(
          useLOTabAnalyticsEventNames
            ? 'click_your_team_lo_contact_button_1'
            : 'click_your_team_re_agent_contact_button_1',
          {
            ctaText: ctaText,
          }
        );

        break;
      case 1:
        reportYourTeamEvent(
          useLOTabAnalyticsEventNames
            ? 'click_your_team_lo_contact_button_2'
            : 'click_your_team_re_agent_contact_button_2',
          {
            ctaText: ctaText,
          }
        );

        break;
      case 2:
        reportYourTeamEvent(
          useLOTabAnalyticsEventNames
            ? 'click_your_team_lo_contact_button_3'
            : 'click_your_team_re_agent_contact_button_3',
          {
            ctaText: ctaText,
          }
        );

        break;
      case 3:
        reportYourTeamEvent(
          useLOTabAnalyticsEventNames
            ? 'click_your_team_lo_contact_button_4'
            : 'click_your_team_re_agent_contact_button_4',
          {
            ctaText: ctaText,
          }
        );

        break;
      case 4:
        reportYourTeamEvent(
          useLOTabAnalyticsEventNames
            ? 'click_your_team_lo_contact_button_5'
            : 'click_your_team_re_agent_contact_button_5',
          {
            ctaText: ctaText,
          }
        );

        break;
      default:
        break;
    }
    setSelectedCta(ctaText);
  };

  const handleClickSendBtn = () => {
    reportYourTeamEvent(
      useLOTabAnalyticsEventNames
        ? 'click_your_team_lo_send_button'
        : 'click_your_team_re_agent_send_button',
      {
        ctaText: sendBtnText,
      }
    );

    if (onSubmit && selectedCta) {
      onSubmit(selectedCta);
    }
  };

  const renderConfigedCTAs = (ctaText: CTAText[]) => {
    return ctaText.map(
      (ele, indx): JSX.Element =>
        ele.text ? (
          <PillButton
            key={indx}
            theme={theme}
            className={theme.OptionButton}
            onKeyDown={onEnterOrSpaceKey(() => {
              handleClickCTA(indx, ele.text);
            })}
            ariaLabel={ele.text}
            onClick={() => {
              handleClickCTA(indx, ele.text);
            }}
            {...(ele.text === selectedCta
              ? selectedCtaButtonStyling
              : LenderCTAButtonPDPConfigStyling)}
          >
            <span className={theme.BtnText}>{ele.text}</span>
          </PillButton>
        ) : (
          <React.Fragment key={indx}></React.Fragment>
        )
    );
  };

  return (
    <div className={theme.ButtonWrapper} data-hc-name={dataHcName}>
      <div className={theme.HelpText}>What can I help you with?</div>
      <YourTeamMainCTAButton
        eventName={
          useLOTabAnalyticsEventNames
            ? 'click_your_team_lo_lender_cta'
            : 'click_your_team_re_agent_lender_cta'
        }
        theme={theme}
        customBackgroundColor={customBackgroundColor}
        customTextColor={customTextColor}
        customBorderRadius={customBorderRadius}
      />
      {
        /* Only allow actions that result in a /your-team/contact/ endpoint being hit if we have
         * required user profile information */
        (userHasFirstnameLastnameAndEmail ||
          (!isLoggedIn && allowUnauthenticatedAccess)) && (
          <>
            {renderConfigedCTAs(ctaTextArr)}
            <PillButton
              theme={theme}
              className={theme.BottomButton}
              dataHcName="your-team-send-button"
              {...(selectedCta !== null
                ? selectedSubmitButtonStyling
                : defaultSubmitButtonStyling)}
              onKeyDown={handleClickSendBtn}
              ariaLabel={'Open another page'}
              onClick={handleClickSendBtn}
              disabled={selectedCta === null}
            >
              {sendBtnText}
            </PillButton>
          </>
        )
      }
    </div>
  );
};

export default YourTeamCTAs;
