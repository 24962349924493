import React from 'react';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';
import defaultTheme from '@client/css-modules/CantFindAddressText.css';

type Props = {
  theme: Theme;
};

const CantFindAddressText: React.FunctionComponent<Props> = ({ theme }) => {
  return (
    <div className={theme.CantFindAddressText}>
      <h2>Why can't I find my address?</h2>
      <dl>
        <div>
          <dt>Address Entry:</dt>
          <dd>
            Because the town name of a specific property may change as a result of
            rezoning or annexation.
          </dd>
        </div>

        <div>
          <dt>Assessment Data:</dt>
          <dd>
            ComeHome aggregates data from public county assessment data. If the
            public record lacks data for a subject property, we cannot generate a
            report.
          </dd>
        </div>
        <div>
          <dt>Address Entry:</dt>
          <dd>
            Please note that at this time we primarily support Single Family
            Detached properties. We do not focus on multi-family, new
            construction, empty lots or commercial properties.
          </dd>
        </div>
      </dl>
    </div>
  );
};

export default themr(
  'CantFindAddressTextThemed',
  defaultTheme
)(CantFindAddressText);
